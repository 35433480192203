<script lang="ts" setup>
   const props = defineProps({
      error: Object,
   })

   const endSession = 'Your session has ended.  Please log in to continue.'
   const maintenanceMode = 'UA Apprentice Hours Portal is temporarily under maintenance.'
   const pageNotFound = '404 - Page Not Found'
   const otherError = 'An error occurred.'

   const handleError = () => clearError({ redirect: '/login' })
</script>

<template>
   <v-container v-if="error.statusCode !== 403"
      class="app-bg h-100" fluid :class="`${$vuetify.display.mobile ? 'px-0 pt-16' : 'd-flex justify-center align-center'}`">
      <v-card class="overflow-visible" :class="$vuetify.display.mobile ? 'rounded-0 mt-16' : ''" :max-width="$vuetify.display.mobile ? '960px' :'640px'" elevation="6">
         <v-card-text>
            <GlobalAppLogo />
            <v-row>
               <v-col cols="12" class="my-3 text-center">
                  <h1 v-if="error.statusCode === 403">{{ endSession }}</h1>
                  <h1 v-else-if="error.statusCode === 404">{{ pageNotFound }}</h1>
                  <h1 v-else-if="error.statusCode === 503">{{ maintenanceMode }}</h1>
                  <h1 v-else>{{ error.statusCode }} - {{ otherError }}</h1>
               </v-col>
               <v-col cols="12" class="d-flex justify-center">
                  <v-btn size="x-large" color="primary" @click="handleError">Home</v-btn>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
   </v-container>
</template>

<style lang="scss" scoped>
   .app-bg {
      background-color: $brand-secondary !important;
   }

   h1 {
      color: $warning !important;
   }
</style>

